'use client';
import { cn } from '@v2/utils';
import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { forwardRef } from 'react';

export const textareaCva = cva(
  'leading-[24px] text-[16px] font-normal focus:outline-none appearance-none border border-gray-300 focus:border-gray-800 text-gray-800 rounded-lg w-full placeholder-gray-500',
  {
    variants: {
      extent: {
        sm: 'px-3 py-2 min-h-[60px]',
        md: 'px-3.5 py-2.5 min-h-[80px]',
        lg: 'px-4 py-3 min-h-[100px]',
      },
    },
    defaultVariants: {
      extent: 'sm',
    },
  }
);

export type TextareaProps = VariantProps<typeof textareaCva> & {
  isError?: boolean;
  errorText?: string;
  containerClassName?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, isError, errorText, extent, containerClassName, ...props },
    ref
  ) => {
    return (
      <div className={cn('flex w-full flex-col gap-y-1.5', containerClassName)}>
        <textarea
          {...props}
          ref={ref}
          className={cn(
            textareaCva({ extent }),
            isError && 'border-primary-300',
            className
          )}
        />
        {isError && errorText && (
          <small className="text-[14px] text-[#F04438]">{errorText}</small>
        )}
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';
